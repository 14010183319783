<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_service-types-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-types.service-types") }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can('MANAGE_SERVICE_TYPES', 'create')"
            :to="{
              name: 'r_add-service-type',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfServiceTypes && listOfServiceTypes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="serviceType in listOfServiceTypes"
        :key="serviceType.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editServiceType(serviceType)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ serviceType.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`service-type${serviceType.id}`"
              name="user"
              :selected="selectedServiceTypes.includes[serviceType.id]"
              :value="serviceType.id"
              @change="selectServiceType(serviceType)"
            />
            <label :for="`service-type${serviceType.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              <span class="highlight">
                {{ serviceType.name }}
              </span>
            </label>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedServiceTypes.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
              v-if="can('MANAGE_SERVICE_TYPES', 'delete')"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedServiceTypes.length +
                "/" +
                listOfServiceTypes.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.ok')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.service-types.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("services.service-types.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "ServiceTypes",
  mixins: [helpOnlineMixin, paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      helpSlug: "service-types",
      selectedServiceTypes: []
    };
  },
  computed: {
    ...mapState("service", ["serviceTypes", "selectedServiceType"]),
    listOfServiceTypes() {
      const query = this.searchQuery;
      if (this.serviceTypes && this.serviceTypes.length) {
        if (query && query.length > 1) {
          return this.serviceTypes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.serviceTypes;
        }
      }
      return null;
    }
  },
  created() {
    this.getServiceTypes();
  },
  methods: {
    ...mapActions("service", ["getServiceTypes", "deleteServiceType"]),
    editServiceType(serviceType) {
      if (this.can("MANAGE_SERVICE_TYPES", "read")) {
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-service-type",
            params: { service_type_id: serviceType.id }
          });
        }, 0);
      }
    },
    selectServiceType(serviceType) {
      if (this.selectedServiceTypes.includes(serviceType.id)) {
        this.selectedServiceTypes.splice(
          this.selectedServiceTypes.indexOf(serviceType.id),
          1
        );
      } else {
        this.selectedServiceTypes.push(serviceType.id);
      }
    },
    setEditing() {
      this.editing = !this.editing;
      this.$router.push({ name: "r_service-types" });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedServiceTypes.length; i++) {
        await this.deleteServiceType(this.selectedServiceTypes[i]);
      }
      this.processing = false;
      this.editing = false;
      this.selectedServiceTypes = [];
      this.$nextTick(() => {
        this.$router.push({ name: "r_service-types" });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
